<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-12 col-lg-12 col-xl-7">
        <h5 class="blue-text font-weight-bold">配信画像リスト</h5>
      </div>
      <div class="col-12 col-md-12 col-lg-12 col-xl-5">
        <div id="top-ation">
          <div class="search">
            <div class="icon">
              <font-awesome-icon
                :icon="{ prefix: 'fas', iconName: 'search' }"
              />
            </div>
            <input
              type="text"
              placeholder="検索"
              v-model="search"
              v-on:keyup="searchData"
            />
          </div>
        </div>
      </div>
      <div class="col-12">
        <div id="approval-list" class="mt-5" v-if="approvalListing">
          <div v-if="approvalListing.length > 0">
            <div
              class="row pt-3 pb-3 item"
              v-for="(el, i) in approvalListing"
              :key="i"
            >
              <div class="col-12 col-md-12 col-lg-12 col-xl-9">
                <div class="row details">
                  <div class="col-12 col-md-12 col-lg-12 col-xl-12">
                    <div class="row">
                      <div class="col-6 col-md-3 col-lg-3 col-xl-2 mb-2">
                        <p class="mb-1 grey-text font-weight-bold">注文ID</p>
                        <p class="m-0 ellipsis-1">{{ el.reference_no }}</p>
                      </div>
                      <div class="col-6 col-md-3 col-lg-3 col-xl-2 mb-2">
                        <p class="mb-1 grey-text font-weight-bold">注文者</p>
                        <p class="m-0 ellipsis-1">
                          {{ formatUserName(el.user) }}
                        </p>
                      </div>
                      <div class="col-6 col-md-3 col-lg-3 col-xl-2 mb-2">
                        <p class="mb-1 grey-text font-weight-bold">注文日</p>
                        <p class="m-0 ellipsis-1">
                          {{
                            el.billing ? formatDate(el.billing.updated_at) : 'N/A'
                          }}
                        </p>
                      </div>
                      <div class="col-6 col-md-3 col-lg-3 col-xl-3 mb-2">
                        <p class="mb-1 grey-text font-weight-bold">状態</p>
                        <p v-if="el.status == 4" class="m-0 ellipsis-1">
                          {{ 'レビューを待っています' }}
                        </p>
                        <p v-if="el.status == 7" class="m-0 ellipsis-1">
                          {{ 'ユーザ修正対応のレビューを待っています' }}
                        </p>
                        <p v-if="el.status != 4 && el.status != 7" class="m-0 ellipsis-1">
                          {{ 'N/A' }}
                        </p>
                      </div>
                      <div class="col-6 col-md-3 col-lg-3 col-xl-3 mb-2">
                        <p class="mb-1 grey-text font-weight-bold">注文カテゴリ</p>
                        <p v-if="el.request_category == 1" class="m-0 ellipsis-1">
                          デジタルステージング
                        </p>
                        <p v-if="el.request_category == 2" class="m-0 ellipsis-1">
                          360°デジタルステージング
                        </p>
                        <p v-if="el.request_category == 3" class="m-0 ellipsis-1">
                          家具消し
                        </p>
                        <p v-if="el.request_category == 4" class="m-0 ellipsis-1">
                          商業用デジタルステージング
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-12 col-lg-12 col-xl-3">
                <div class="row actions">
                  <div class="col-12">
                    <router-link
                      :to="{
                        name: 'adminRoute-approval-view',
                        params: {
                          id: el.id,
                        },
                      }"
                    >
                      <button class="btn btn-ds blue white-text" v-waves.light>
                        詳細を表示
                      </button>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <pagination
              class="mt-3 mb-0"
              :data="pagination.data"
              :limit="pagination.limit"
              :show-disabled="pagination.showDisabled"
              :size="pagination.size"
              :align="pagination.align"
              @pagination-change-page="paginateData"
            />
          </div>
          <div v-else>
            <b-card class="shadow-1 pt-5 pb-5 text-center" no-header no-footer>
              <img src="/img/icons/ico-info.svg" alt="ico-info" />
              <h3 class="m-0 mt-3 grey-text">表示できる内容がありません。</h3>
            </b-card>
          </div>
        </div>
        <div class="mt-5" v-else>
          <div class="row m-0 pt-3 pb-3 item" v-for="(el, i) in 10" :key="i">
            <div class="col-12 col-md-12 col-lg-12 col-xl-9">
              <div class="row details">
                <div class="col-12 col-md-12 col-lg-12 col-xl-10">
                  <div class="row">
                    <div class="col-6 col-md-3 col-lg-3 col-xl-3 mb-4">
                      <b-skeleton width="100%"></b-skeleton>
                      <b-skeleton width="80%"></b-skeleton>
                    </div>
                    <div class="col-6 col-md-3 col-lg-3 col-xl-3 mb-4">
                      <b-skeleton width="100%"></b-skeleton>
                      <b-skeleton width="50%"></b-skeleton>
                    </div>
                    <div class="col-6 col-md-3 col-lg-3 col-xl-3 mb-4">
                      <b-skeleton width="100%"></b-skeleton>
                      <b-skeleton width="70%"></b-skeleton>
                    </div>
                    <div class="col-6 col-md-3 col-lg-3 col-xl-3 mb-4">
                      <b-skeleton width="100%"></b-skeleton>
                      <b-skeleton width="50%"></b-skeleton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-12 col-lg-12 col-xl-3">
              <div class="row actions">
                <div class="col-12">
                  <b-skeleton
                    type="button"
                    height="56px"
                    width="100%"
                  ></b-skeleton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Import Services
import { adm_approvalService_GetAllForApproval } from '../../services/admin/approval';
import { formatUserName } from '../../common/Utility';

// Import Moment JS
import moment from 'moment';

export default {
  metaInfo() {
    return {
      title: 'Digital Staging - Approval | List',
    };
  },

  data() {
    return {
      search: null,
      approvalListing: null,
      pagination: {
        data: null,
        limit: 3,
        showDisabled: false,
        size: 'default',
        align: 'center',
      },
    };
  },

  watch: {
    $route() {
      this.getResults(this.getUrlQueryParamsPage());
    },
    'pagination.limit'(newVal) {
      this.pagination.limit = parseInt(newVal);

      if (this.pagination.limit < 0) {
        this.pagination.limit = 0;
      }
    },
  },

  mounted() {
    this.getResults(this.getUrlQueryParamsPage());
  },

  methods: {
    formatUserName,
    getUrlQueryParamsPage() {
      let currentRouteURLPrams = new URLSearchParams(location.search);
      return currentRouteURLPrams.get('page');
    },
    paginateData(page) {
      let _this = this;
      let currentRouteURLPrams = new URLSearchParams(location.search);
      let url_page = currentRouteURLPrams.get('page');
      if (url_page != page) {
        _this.$router.push({
          name: 'adminRoute-approval-list',
          query: { page: page },
        });
      }
    },
    getResults(page) {
      let _this = this;

      let accessToken = localStorage.getItem('_accessToken');

      _this.approvalListing = null;

      adm_approvalService_GetAllForApproval({
        page: page,
        token: accessToken,
      })
        .then((response) => {
          let data = response.data.result;
          console.log(data);

          _this.approvalListing = data.data;
          _this.pagination.data = data;
        })
        .catch((error) => {
          console.log(error);

          _this.$bvToast.toast(
            'データの取得中にエラーが起こりました。再度お試しください。',
            {
              title: 'システムエラー',
              autoHideDelay: 5000,
              variant: 'danger',
              appendToast: true,
            }
          );
        });

      _this.$store.state.currentPaginationPage = page;
    },

    searchData(e) {
      console.log(this.search);
    },

    formatStyle(type) {
      let typeName;

      switch (type) {
        case 1:
          typeName = 'Contemporary';
          break;
        case 2:
          typeName = 'Farmhouse';
          break;
        case 3:
          typeName = 'Glam';
          break;
        case 4:
          typeName = 'Industrial';
          break;
        default:
          typeName = 'N/A';
          break;
      }

      return typeName;
    },

    formatDate(date) {
      return moment(date).format('YYYY-MM-DD');
    },

    formatPrice(value) {
      let formatter = new Intl.NumberFormat('ja-JP', {
        style: 'currency',
        currency: 'JPY',
        minimumFractionDigits: 0,
      });

      return formatter.format(value);
    },
  },
};
</script>

<style scoped>
/* Init Style */
/* Custom Style */
#top-ation {
  display: flex;
}

@media only screen and (max-width: 767px) {
  #top-ation {
    display: block;
  }
}

#top-ation .search {
  display: flex;
  width: 100%;
  height: 100%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border: 1px solid #d1d1d1;
}

@media only screen and (max-width: 767px) {
  #top-ation .search {
    margin-bottom: 14px;
  }
}

#top-ation .search .icon {
  font-size: 24px;
  padding: 10px;
  text-align: center;
  background: #fff;
  color: #d1d1d1;
}

#top-ation .search input {
  width: 100%;
  border: none;
  padding: 10px;
}

#top-ation .search input:focus {
  outline: none;
}

#approval-list .item {
  margin: 0 0 20px 0;
  border-radius: 20px;
  background: #f5f5f5;
}

@media only screen and (max-width: 1199px) {
  #approval-list .item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  }
}

@media only screen and (max-width: 1199px) {
  #approval-list .item .details {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
  }
}

#approval-list .item .details .img-carousel {
  height: 130px;
  background: #e9e9e9;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

@media only screen and (max-width: 1199px) {
  #approval-list .item .details .img-carousel {
    height: 350px;
  }
}

@media only screen and (max-width: 767px) {
  #approval-list .item .details .img-carousel {
    height: 250px;
  }
}
</style>
